import { Rating } from '../../types/Rating';
import { ProtocolStats, ProtocolWidgetConfig } from '../../types';
import AnteTrust from '../../assets/ante-trust.svg';
import './ProtocolWidget.css';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { MAINNET_CHAINS } from '../../common/utils';

export async function renderProtocolWidget(container: Element, config: ProtocolWidgetConfig) {
  const protocol: string = config.name || '';
  // Default to any mainnet chain
  const chainId: string = config.chain || Object.keys(MAINNET_CHAINS)[0];
  const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/protocols/${protocol}/stats?chainId=${chainId}`);
  if (response.status !== 200) {
    return null;
  }
  const protocolStats: ProtocolStats = await response.json();

  if (!protocol || protocolStats === undefined) {
    return null;
  }

  const { aggregateTrustScore: trustScore, totalTvl: tvl, totalStakedByProtocol } = protocolStats;

  let rating: Rating = 'NR';

  if (tvl >= 100 && trustScore >= 90) {
    rating = "S";
  } else if (tvl >= 30 && trustScore >= 85) {
    rating = "AA";
  } else if (tvl >= 10 && trustScore >= 80) {
    rating = "A";
  } else {
    return null;
  }

  const learnMoreLink = `<a href="${process.env.REACT_APP_ANTE_BASE_URL}/#/protocol/${protocol}" target='_blank'>Learn more</a>`;
  const TOOLTIPS = {
    S: `<b>S-rated</b> protocols have an overall <b>trust score over 90</b> and <b>at least 100 ETH staked</b> in total across their Ante Tests. <br /><br /> ${learnMoreLink}`,
    AA: `<b>AA-rated</b> protocols have an overall <b>trust score over 85</b> and <b>at least 30 ETH staked</b> in total across their Ante Tests. <br /><br /> ${learnMoreLink}`,
    A: `<b>A-rated</b> protocols have an overall <b>trust score over 80</b> and <b>at least 10 ETH staked</b> in total across their Ante Tests. <br /><br /> ${learnMoreLink}`,
  }

  container.innerHTML = `<a href=${process.env.REACT_APP_ANTE_BASE_URL}/#/protocol/${protocol} target='_blank' class='ante-protocol-widget--link'>
    <div class='ante-protocol-widget--container'>
      <img src="${AnteTrust}" alt="Ante Trust ${rating}"  />
      <div class='ante-protocol-widget--rating-container'>
        <span style="font-size: ${rating.length === 1 ? 18 : 12}px">${rating}</span>
      </div>
    </div>
  </a>`;

  const stakeText = totalStakedByProtocol > 0 ? `<b>${protocol}</b> has staked their own funds behind the security of their code.` : `Funds have been staked behind the security of ${protocol}'s code.`

  const tooltipContent = `${stakeText} ${TOOLTIPS[rating]}`;

  tippy(`.${container.className} a`, {
    theme: 'ante',
    content: tooltipContent,
    interactive: true,
    allowHTML: true,
    delay: 100
  });
}

export default {}