import { getArrayOfElements, validateTargets } from './common/utils';
import { Instance, ProtocolWidgetConfig, Targets, TestWidgetConfig } from 'types';
import { renderProtocolWidget } from './components/ProtocolWidget/ProtocolWidget';
import { renderTestWidget } from './components/TestWidget/TestWidget';
import './index.css';

function AnteProtocolWidget(targets: Targets, config: ProtocolWidgetConfig): void {
  if (!validateTargets(targets)) {
    return;
  }

  const elements = getArrayOfElements(targets);

  elements.forEach(element => {
    renderProtocolWidget(element, config);
  });
}

function AnteTestWidget(targets: Targets, config: TestWidgetConfig): void {
  if (!validateTargets(targets)) {
    return;
  }

  const elements = getArrayOfElements(targets);

  elements.forEach(element => {
    renderTestWidget(element, config);
  });
}

const AnteWidget: Instance = {
  Protocol: AnteProtocolWidget,
  Test: AnteTestWidget
}

export default AnteWidget;