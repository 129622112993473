import { Pool, TestWidgetConfig } from '../../types';
import AnteScore from '../../assets/ante-score.svg';
import './TestWidget.css';
import { SupportedChain, supportedChains } from '../../types/SupportedChain';
import 'tippy.js/dist/tippy.css';
import tippy from 'tippy.js';

export async function renderTestWidget(container: Element, config: TestWidgetConfig) {
  if (
    config.chain === undefined ||
    config.address === undefined ||
    !supportedChains.find(chain => chain === (config.chain || '').toLowerCase())
  ) {
    console.error('Invalid chain or address provided.');
    return null;
  }

  const address: string = config.address;
  const chain = config.chain as SupportedChain;

  const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/pools/${address}/${chain}`);
  if (response.status !== 200) {
    console.warn('Unable to fetch the requested pool.');
    return null;
  }
  const pool: Pool = await response.json();

  if (!address || pool === undefined) {
    console.error('Invalid pool.');
    return null;
  }

  container.innerHTML = ` <a href="${process.env.REACT_APP_ANTE_BASE_URL}/#/contract/${address}" target='_blank' class='ante-test-widget--link'>
    <div class='ante-test-widget--container'>
      <img src="${AnteScore}" alt="${String(pool.trustScore)}" />
      <div class='ante-test-widget--score-container'>
        <span>${pool.trustScore === 100 ? pool.trustScore : pool.trustScore?.toFixed(1)}</span>
      </div>
    </div>
  </a>`;

  let tooltipContent = `<a href="${process.env.REACT_APP_ANTE_BASE_URL}/#/contract/${address}" target='_blank'>"${pool.anteTestName}"</a>.`;

  if (pool.protocolStaked) {
    tooltipContent = `Funds have been staked behind the security of this code in the Ante Test ${tooltipContent}`;
  }

  tippy(`.${container.className} a`, {
    theme: 'ante',
    content: tooltipContent,
    interactive: true,
    allowHTML: true,
    delay: 100
  });
}

export default {}