import { Targets } from "types";
import { SupportedChain } from "../types/SupportedChain";

type SupportedChains = {
  [key in SupportedChain]: Record<string, any>;
}

export const MAINNET_CHAINS = {
  '0x1': {},
  '0xa86a': {}
};

export const TESTNET_CHAINS = {
  '0x4': {},
  '0xa869': {}
};

export const SUPPORTED_CHAINS: SupportedChains = { ...MAINNET_CHAINS, ...TESTNET_CHAINS }

export const getBaseUrl = (chainId: string): string => {
  return Object.keys(TESTNET_CHAINS).includes(chainId) ?
    process.env.REACT_APP_ANTE_TESTNET_BASE_URL || '' :
    process.env.REACT_APP_ANTE_BASE_URL || '';
}

export function validateTargets(targets: Targets): boolean {
  const didPassFalsyValue = !targets;
  const didPassPlainObject =
    Object.prototype.toString.call(targets) === '[object Object]' &&
    !(targets as any).addEventListener;

  if (didPassFalsyValue || didPassPlainObject) {
    console.error(`First argument passed to AnteWidget isn't supported. Please use a string selector or a HTML element`);
    return false;
  }

  return true;
}

export function arrayFrom(value: ArrayLike<any>): any[] {
  return [].slice.call(value);
}

export function isType(value: any, type: string): boolean {
  const str = {}.toString.call(value);
  return str.indexOf('[object') === 0 && str.indexOf(`${type}]`) > -1;
}

export function isElement(value: unknown): value is Element | DocumentFragment {
  return ['Element', 'Fragment'].some((type) => isType(value, type));
}

export function isNodeList(value: unknown): value is NodeList {
  return isType(value, 'NodeList');
}

export function getArrayOfElements(value: Targets): Element[] {
  if (isElement(value)) {
    return [value];
  }

  if (isNodeList(value)) {
    return arrayFrom(value);
  }

  if (Array.isArray(value)) {
    return value;
  }

  return arrayFrom(document.querySelectorAll(value));
}